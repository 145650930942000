<template>
  <div class="items-consumption">
    <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <el-input
          v-model="submitData.Word"
          placeholder="请输入单号/姓名/手机号/卡号"
          size="mini"
          clearable
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAdvanceSearch"
          >高级查询</el-button
        >
      </div>

      <div class="df-col">
        <DfExportButton
          ref="dfExportButton"
          :fileName="fileName"
          :checkAllow="false"
          @export="onExportExcel"
        ></DfExportButton>
      </div>
    </div>

    <!-- start 项目分类统计明细表 -->
    <el-table
      class="items-table"
      v-loading="loading"
      :data="billList"
      :max-height="tableMaxHeight"
      :span-method="objectSpanMethod"
      stripe
      border
    >
      <el-table-column
        align="center"
        :width="adaptiveDefault.tableColumnWidth.billInfo"
      >
        <template slot="header" slot-scope="head">
          <div class="bill-head">
            <!-- <div class="bill-no" @click="onChangeSort('BillNo')">
              <span>单号</span>
              <div class="icon-box">
                <i
                  class="el-icon-caret-top"
                  :class="sortInfo.BillNo ? '' : 'sort-active'"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="sortInfo.BillNo ? 'sort-active' : ''"
                ></i>
              </div>
            </div>
            <div class="software-date" @click="onChangeSort('CheckoutTime')">
              <span>消费时间</span>
              <div class="icon-box">
                <i
                  class="el-icon-caret-top"
                  :class="sortInfo.CheckoutTime ? '' : 'sort-active'"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="sortInfo.CheckoutTime ? 'sort-active' : ''"
                ></i>
              </div>
            </div> -->
            <div class="bill-no">
              <span>单号</span>
            </div>
            <div class="software-date">
              <span>消费时间</span>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.BillNo !== "" ? scope.row.BillNo : "无账单号" }}</p>
          <p>
            消：{{
              util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(scope.row.CheckoutTime * 1000)
              )
            }}
          </p>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="" label="" align="center" width="1"> </el-table-column> -->
      <el-table-column
        prop=""
        label="客户"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.memberInfo"
      >
        <template slot-scope="scope">
          <div class="member-box">
            <span>{{ scope.row.MemberName }}</span>
            <span>{{ scope.row.BillSex ? "男" : "女" }}</span>
            <span>{{ scope.row.MemberPhone }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="计客数"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.calcClientText"
      >
        <template slot-scope="scope">
          <div class="calc-box">
            <span class="color pointer">{{ scope.row.CalcClientText }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="消费金额"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.amountInfo"
      >
        <template slot-scope="scope">
          <div class="amount-box">
            <template
              v-if="
                scope.row.BillAmount ||
                  scope.row.BillDisc ||
                  scope.row.BillCoupon
              "
            >
              <span v-show="scope.row.BillAmount"
                >总金额：{{ scope.row.BillAmount }}</span
              >
              <span v-show="scope.row.BillDisc"
                >折扣：{{ scope.row.BillDisc }}</span
              >
              <span v-show="scope.row.BillCoupon"
                >优惠券：{{ scope.row.BillCoupon }}</span
              >
              <span class="line"></span>
            </template>

            <div
              class="payment-info"
              v-for="(item, index) in scope.row.PaymentInfoList"
              :key="item.PaymentGuid"
            >
              <span
                class="payment-money color"
                :class="item.PaymentType == 1 ? 'active' : ''"
                >¥ {{ item.PayMoney }}</span
              >
              <span :class="item.PaymentType == 1 ? 'active' : ''">{{
                item.PaymentName
              }}</span>
              <span
                v-show="item.VipOpenDate"
                class="time"
                :class="item.PaymentType == 1 ? 'active' : ''"
                >{{
                  util.dateFormat(
                    "YYYY-mm-dd",
                    new Date(item.VipOpenDate * 1000)
                  )
                }}开卡</span
              >
            </div>
            <div
              class="vip-items-info"
              v-for="(item, index) in scope.row.VipItemCardList"
              :key="item.VipGuid"
            >
              <span>{{ item.CardName }}</span>
              <span v-show="item.OpenDate" class="time"
                >{{
                  util.dateFormat("YYYY-mm-dd", new Date(item.OpenDate * 1000))
                }}开卡</span
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="消费内容"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.itemInfo"
      >
        <template slot-scope="scope">
          <div class="items-box">
            <el-table
              v-if="scope.row.ItemInfoList.length > 0"
              :data="scope.row.ItemInfoList"
              :show-header="false"
              :border="false"
              :span-method="objectSpanMethodItem"
              class="custom-table"
              v-loading="loading"
            >
              <el-table-column
                align="center"
                :width="adaptiveDefault.tableColumnWidth.itemInfo"
              >
                <template slot-scope="scope1">
                  <div>
                    <div>
                      <span>{{ scope1.row.ItemName }}</span>
                      <span> ¥{{ scope1.row.ItemAmount }}</span>
                    </div>
                    <span class="color" v-show="scope1.row.ItemTypeText">
                      ({{ scope1.row.ItemTypeText }})
                    </span>
                    <span class="dept-name">
                      {{ scope1.row.DeptName || "无部门" }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :width="adaptiveDefault.tableColumnWidth.keepPerfor"
              >
                <template slot-scope="scope2">
                  <div class="three-level">
                    <el-table
                      v-if="scope2.row.EmplInfoList.length > 0"
                      class="three-table"
                      :data="scope2.row.EmplInfoList"
                      :show-header="false"
                      :border="false"
                    >
                      <el-table-column
                        align="center"
                        prop="PerforFinal"
                        :width="adaptiveDefault.tableColumnWidth.keepPerfor"
                      >
                      </el-table-column>
                      <el-table-column
                        align="center"
                        prop="RoyaFinal"
                        :width="adaptiveDefault.tableColumnWidth.keepRoya"
                      >
                      </el-table-column>
                      <el-table-column
                        align="center"
                        :width="adaptiveDefault.tableColumnWidth.emplInfo"
                      >
                        <template slot-scope="scope3">
                          <div class="empl-box">
                            <div>
                              <span class="empl-no">{{
                                scope3.row.EmplNo
                              }}</span>
                              <span>{{ scope3.row.LevelName }}</span>
                            </div>
                            <span>[{{ scope3.row.EmplName }}]</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        align="center"
                        :width="adaptiveDefault.tableColumnWidth.assignRoya"
                      >
                        <template slot-scope="scope3">
                          <div class="assign-box">
                            <span>
                              {{
                                scope3.row.AssignMark == 1 ? "指定" : "非指定"
                              }}
                            </span>
                            <span>
                              {{ scope3.row.AssignRoyaFinal }}
                            </span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        align="center"
                        :width="adaptiveDefault.tableColumnWidth.inventRoya"
                      >
                        <template slot-scope="scope3">
                          {{ scope3.row.InventRoyaFinal }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :width="adaptiveDefault.tableColumnWidth.keepRoya"
              >
                <template slot-scope="scope">
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :width="adaptiveDefault.tableColumnWidth.emplInfo"
              >
                <template slot-scope="scope">
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template
                  slot-scope="scope"
                  :width="adaptiveDefault.tableColumnWidth.assignRoya"
                >
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="业绩"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.keepPerfor"
      >
      </el-table-column>
      <el-table-column
        label="提成"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.keepRoya"
      >
      </el-table-column>
      <el-table-column
        label="员工"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.emplInfo"
      >
      </el-table-column>
      <el-table-column
        align="center"
        :width="adaptiveDefault.tableColumnWidth.assignRoya"
      >
        <template slot="header" slot-scope="scope">
          <div>指定</div>
          <div>提成</div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :width="adaptiveDefault.tableColumnWidth.inventRoya"
      >
        <template slot="header" slot-scope="scope">
          <div>创单</div>
          <div>提成</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="" label="业绩"></el-table-column>
      <el-table-column prop="" label="提成"></el-table-column>
      <el-table-column prop="" label="员工"></el-table-column>
      <el-table-column prop="" label="指定提成"></el-table-column>
      <el-table-column prop="" label="创单业绩"></el-table-column> -->
      <el-table-column
        prop="OperatorName"
        label="操作人"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.operator"
      ></el-table-column>
    </el-table>
    <!-- end 项目分类统计明细表 -->

    <div v-if="billList.length > 0" class="footer-box">
      <div class="vip-total">
        账户明细：{
        <span v-for="(item, index) in totalStatis.VipStatisItems" :key="index"
          >{{ item.Name }}：{{ item.Amount }}元{{
            index == totalStatis.VipStatisItems.length - 1 ? "" : "，"
          }}</span
        >
        }
      </div>
      <div class="pay-total">
        结算统计：{
        <span v-for="(item, index) in totalStatis.PayStatisItems" :key="index"
          >{{ item.Name }}：{{ item.Amount }}元{{
            index == totalStatis.PayStatisItems.length - 1 ? "" : "，"
          }}</span
        >
        }
      </div>
    </div>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.PageSize"
      :current-page="submitData.PageIndex"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <AdvancedQuery
      ref="AdvancedQuery"
      v-model="submitData"
      @search="onSearch"
    ></AdvancedQuery>
  </div>
</template>

<script>
import Report from "@/api/report.js";

import AdvancedQuery from "./components/advance-query-dialog.vue";

export default {
  components: { AdvancedQuery },

  data() {
    return {
      tableMaxHeight: "",
      fileName: "",
      totalRecord: 0,
      pageIndex: 1,
      pageSize: 10,
      multipleDate: [],
      submitData: {
        BeginDate: "",
        EndDate: "",
        BeginBillNo: "",
        EndBillNo: "",
        Word: "",
        MemberType: "",
        PaymentGuid: "",
        NatureNo: "",
        CardGuid: "",
        CheckoutAdmin: "",
        ItemGuid: "",
        DutyNo: "",
        LevelGuid: "",
        EmplGuid: "",
        PageIndex: 1,
        PageSize: 10,
      },
      billList: [],
      totalStatis: {
        PayStatisItems: [],
        VipStatisItems: [],
      },
      tableList: [],
      loading: false,
      isCheckEmpl: false,

      mediaWidthAdaptive: {
        "1000": {
          tableColumnWidth: {
            billInfo: "100",
            // payTime: "80",
            memberInfo: "",
            calcClientText: "60",
            amountInfo: "",
            itemInfo: "100",
            keepPerfor: "50",
            keepRoya: "50",
            emplInfo: "100",
            assignRoya: "50",
            inventRoya: "50",
            operator: "60",
          },
        },
        "1280": {
          tableColumnWidth: {
            billInfo: "100",
            // payTime: "140",
            memberInfo: "",
            calcClientText: "60",
            amountInfo: "",
            itemInfo: "140",
            keepPerfor: "60",
            keepRoya: "60",
            emplInfo: "120",
            assignRoya: "60",
            inventRoya: "60",
            operator: "80",
          },
        },
        "1920": {
          tableColumnWidth: {
            billInfo: "200",
            // payTime: "160",
            memberInfo: "",
            calcClientText: "90",
            amountInfo: "",
            itemInfo: "220",
            keepPerfor: "80",
            keepRoya: "80",
            emplInfo: "180",
            assignRoya: "80",
            inventRoya: "80",
            operator: "90",
          },
        },
      },
      adaptiveDefault: {
        tableColumnWidth: {
          billInfo: "",
          // payTime: "",
          memberInfo: "",
          calcClientText: "",
          amountInfo: "",
          itemInfo: "",
          keepPerfor: "",
          keepRoya: "",
          emplInfo: "",
          assignRoya: "",
          inventRoya: "",
          operator: "",
        },
      },
    };
  },
  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState();
    await this.queryWorkSellBillRecord();
    // await this.onPaging();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight, clientWidth } = this.util.getDocumentElementInfo();
      let tableMaxHeight = clientHeight - (100 + 40 + 68 + 80);

      this.tableMaxHeight = tableMaxHeight > 400 ? tableMaxHeight : 400;
      for (let key in this.mediaWidthAdaptive) {
        // console.log(clientWidth);
        if (clientWidth <= Number(key)) {
          this.adaptiveDefault = {};
          this.adaptiveDefault = JSON.parse(
            JSON.stringify(this.mediaWidthAdaptive[key])
          );
          break;
        }
      }
    },

    // 查询项目消费数据
    async queryWorkSellBillRecord(isExport = 0) {
      this.loading = true;
      try {
        let { data } = await Report.queryWorkSellBillRecord(
          this.submitData,
          isExport
        );
        if (isExport) {
          return (this.fileName = data);
        } else if (this.submitData.PageIndex == 1) {
          this.totalStatis = data.statis;
        }
        this.billList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    onExportExcel() {
      this.queryWorkSellBillRecord(1);
    },

    // 假分页
    onPaging() {
      let billList = JSON.parse(JSON.stringify(this.billList));
      let pageSize = this.pageSize;
      let pageIndex = this.pageIndex;
      let start = (pageIndex - 1) * pageSize;
      let end = pageIndex * pageSize;
      let list = billList.slice(start, end);
      this.tableList = list;
      // console.log(this.tableList);
    },

    async onSearch() {
      this.submitData.PageIndex = 1;
      await this.queryWorkSellBillRecord();
      // await this.onPaging();
    },

    // 打开更多查询对话框
    onAdvanceSearch() {
      this.$refs.AdvancedQuery.onShowDialog();
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { dayTime: event });
      this.initDateState();
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //return this.twoDimensionalArray(rowIndex, columnIndex);

      if (columnIndex == 4) {
        return {
          rowspan: 1, //rowIndex % 2 == 0 ? 2 : 0,
          colspan: 6, //rowIndex % 2 == 0 ? 5 : 0
        };
      } else if (columnIndex > 4 && columnIndex < 10) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },

    objectSpanMethodItem({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        return {
          rowspan: 1, //rowIndex % 2 == 0 ? 2 : 0,
          colspan: 4, //rowIndex % 2 == 0 ? 5 : 0
        };
      } else if (columnIndex > 1) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.PageIndex = 1;
      this.submitData.PageSize = event;
      this.queryWorkSellBillRecord();
      // this.onPaging();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.PageIndex = event;
      this.queryWorkSellBillRecord();
      // this.onPaging();
    },
  },
};
</script>

<style lang="less" scoped>
.items-consumption {
  .df-row {
    margin-top: 20px;

    .df-col {
      .el-input {
        width: 200px;
      }
    }
  }

  .items-table {
    .bill-head {
      .flex-row;
      justify-content: center;
      width: 100%;

      > div {
        .flex-row;
        justify-content: center;
        margin: 0 10px;
        // cursor: pointer;

        .icon-box {
          .flex-col;
          margin-left: 2px;
          color: #ddd;
          font-size: 14px;

          i {
            line-height: 0;
            padding: 3.5px 0;
          }
        }
      }

      .sort-active {
        color: #409eff;
      }
    }
    ::v-deep .cell {
      padding: 0;
    }

    ::v-deep tbody > tr {
      td:nth-child(6) {
        padding: 0;
        > .cell {
          min-height: 60px;
        }
      }
    }

    ::v-deep tr {
      &:last-child {
        td:last-child {
          border-right: 0;
        }
      }
      td:last-child {
        // border-bottom: 0;
        border-right: 0;
      }
    }

    ::v-deep .custom-table {
      height: 100%;
      min-height: 60px;
      border-right: 0 !important;
      border-bottom: 0 !important;
      &,
      & * {
        overflow: hidden;
      }

      &::before {
        display: none;
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
        min-height: 60px;
      }
      > .el-table__body-wrapper {
        > table > tbody > tr {
          &:last-child {
            > td {
              border-bottom: 0;
            }
            > td:last-child {
              border-right: 0;
              .three-level, .three-table {
                border-bottom: 0 !important;
              }
            }
          }
          td {
            padding: 0;
          }
          > td:last-child {
            border-bottom: 0;
          }
        }
      }

      .cell {
        height: 100%;

        > div {
          .flex-col;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }

      .second-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr:last-child
        > td {
        border-bottom: 0;
      }

      .second-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr
        > td:last-child,
      .three-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr
        > td:last-child {
        border-right: 0;
      }

      .three-level{
        border-bottom: 1px solid #bbb;
      }
    }

    ::v-deep .three-table {
      height: 100%;
      border-right: 0 !important;
      border-bottom: 0 !important;

      &::before {
        display: none;
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
        min-height: 60px;
      }
      > .el-table__body-wrapper {
        > table > tbody > tr {
          &:last-child {
            > td {
              border-bottom: 0;
            }
            > td:last-child {
              border-right: 0;
              .three-table {
                border-bottom: 0 !important;
              }
            }
          }
          td {
            padding: 0;
          }
          > td:last-child {
            // border-bottom: 0;
          }
        }
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
      }
      .cell {
        .flex-col;
        justify-content: center;
        height: 100%;

        > div {
          .flex-col;
          justify-content: center;
          height: 100%;

          .empl-no {
            margin-right: 10px;
          }
        }
      }
    }

    .member-box,
    .calc-box,
    .amount-box {
      .flex-col;
    }

    .line {
      width: 100%;
      border-bottom: 1px dashed #ccc;
    }
  }

  .payment-info,
  .vip-items-info {
    .time {
      display: block;
      width: 100%;
    }
    span {
      margin-right: 3px;
    }
  }

  .footer-box {
    .flex-col;
    align-items: unset;
    margin-block-start: 5px;

    .flex-col;
    align-items: unset;

    .vip-total {
      color: @warning-color;
    }
    .pay-total {
      color: @succes-color;
    }
  }

  .el-pagination {
    float: right;
    margin-block-start: 10px;
  }
}
</style>
